<template>
	<StatisticsSums scope="monthly" />
</template>

<script>
import StatisticsSums from "../../components/statistics/StatisticsSums";

export default {
	components: {
		StatisticsSums,
	},
};
</script>
